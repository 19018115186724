import {EventItem} from "../services/datafetch.ts"
import {get_field_description} from "../locales/i18n.ts";

interface EventCodeLookupProps {
    event: EventItem;
    lang: string;
}



export default function EventCodeLookup(props: EventCodeLookupProps) {     

    //console.log(props.event);
    if(props.event!=null){
        const fields_des = get_field_description(props.lang);
        return (
            <ul>                    
            {props.event.map((name) => 
           <li key={name}>
           <div class="mt-5">
           <p class="max-w-lg text-sm font-semibold leading-relaxed text-gray-400">{fields_des[0]}</p>
           <p class="text-center mb-2 ml-10 text-left text-black-500 dark:text-gray-600">{name.code}</p>    
           <p class="max-w-lg text-sm font-semibold leading-relaxed text-gray-400">{fields_des[1]}</p>
           <p class="text-left mb-2 ml-10  text-black-500 dark:text-gray-600">{name.event}</p>    
           <p class="max-w-lg text-sm font-semibold leading-relaxed text-gray-400">{fields_des[2]}</p>
           <p class="text-left mb-2 ml-10 text-black-500 dark:text-gray-600">{name.action}</p>    
           </div>
            </li>
            )}
            </ul>);
    }
   
    
}

