
export  function get_langcode(lang:string):string {
    switch(lang){
      case "zh-CN":
      case "zh":
          return "zh";
      case "en-US":
      case "en":
          return "en";
      case "ru-RU":
      case "ru":
          return "ru";
      case "pt-BR":
      case "pt":
          return "pt";
      default:
          return "en";
    }
}

export function get_title(lang:string){    
    //console.log(lang);
    switch(get_langcode(lang)) {
        case "zh": return "智能表错误码查询工具";
        case "en": return "Smart Meter Error Code Lookup Tool";
        case "ru": return "Инструмент поиска кода ошибки интеллектуального счетчика";
        case "pt": return "Ferramenta de pesquisa de código de erro do medidor inteligente";
    }
}

export function get_field_description(lang:string){
    
    switch(get_langcode(lang)) {
        case "zh": return ["错误码","错误码描述","应对措施"];
        case "en": return ["Error Code","Description","Action"];
        case "ru": return ["Код ошибки","Описание","Действие"];
        case "pt": return ["Erro de código","Descrição","Ação"];
    }
}